import { useEffect } from 'react';
import { useReCache } from '@medifind/hooks';
import { getAddressDetailsFromPlaceId, getCountryByCode, getProvinceByCode, locationAutocomplete } from '@medifind/interface';
import { useRouter } from '@medifind/router';
import { getFidelity } from '@medifind/utils';
import { setAdditionalRouteParams, setLocationFromQuery, useRouteStore } from '@medifind/zustand';

export const useUrlPathLocation = () => {
  const {
    routeUUID,
    pathParams: { countryCode, stateProvinceCode, city, zipPostalCode },
  } = useRouteStore();

  const country = useReCache(
    'getCountryByCode',
    async () => {
      return getCountryByCode({ countryCode })
        .then((countryData) => countryData?.preferredName)
        .catch((e) => '');
    },
    [countryCode],
    [countryCode],
  );

  const province = useReCache(
    'getProvinceByCode',
    async () => {
      return getProvinceByCode({ countryCode, stateProvinceCode, size: 1 })
        .then((data) => data[0]?.stateProvinceAsciiName)
        .catch((e) => '');
    },
    [countryCode, stateProvinceCode],
    [countryCode, stateProvinceCode],
  );

  const urlLocation = useReCache(
    'setUrlLocation',
    async () => {
      const params = { city, country, countryCode, stateProvince: province, stateProvinceCode, zipPostalCode };
      if (country || countryCode) {
        const cleanCountry = country ? country.replace(/^united/i, 'The United') : null;
        if (cleanCountry) params.country = cleanCountry;
        if (city) {
          params.shortLocationString = city;
          params.mediumLocationString = `${params.shortLocationString}${
            stateProvinceCode && isNaN(stateProvinceCode) ? ', ' + stateProvinceCode : ', ' + province
          }`;
          params.locationString = `${params.mediumLocationString}, ${countryCode ? countryCode : cleanCountry}`;

          const geocode =
            (await locationAutocomplete({ input: params.locationString }).catch(() => ({ predictions: [] })))?.predictions?.[0] ?? {};
          const { value } = geocode;
          if (value) {
            const data = await getAddressDetailsFromPlaceId({ placeId: value }).catch(() => ({ result: {} }));
            const place = data?.result;
            if (place.lat && place.lon) {
              params.lat = place.lat;
              params.lon = place.lon;
            }
          } else {
            // we want to null out city / fall back to state if we cant find a geoplaceId
            // this should have the fidelity correctly adjust as well
            // it will be fine that the url doesnt change because it reflects the result for that location
            params.city = null;
          }
        }
        if (!params?.city) {
          if (stateProvinceCode && (isNaN(stateProvinceCode) || province)) {
            params.shortLocationString = !province ? stateProvinceCode : province;
            params.mediumLocationString = `${params.shortLocationString}, ${countryCode ? countryCode : cleanCountry}`;
            params.locationString = params.mediumLocationString;
          } else {
            params.shortLocationString = `${cleanCountry ? cleanCountry : countryCode}`;
            params.mediumLocationString = params.shortLocationString;
            params.locationString = params.shortLocationString;
          }
        }
        const fidelity = getFidelity(null, zipPostalCode, params.city, params.stateProvinceCode, params.countryCode);
        params.fidelity = fidelity;
      }
      setLocationFromQuery(params);
      return params;
    },
    [city, country, countryCode, province, stateProvinceCode],
    [country, stateProvinceCode ? province : country],
  );

  useEffect(() => {
    if (urlLocation) {
      setAdditionalRouteParams({ urlLocation: urlLocation }, routeUUID);
    }
  }, [urlLocation, routeUUID]);

  return urlLocation;
};

export const useUrlQueryLocationString = () => {
  const {
    queryParams: { locationString },
  } = useRouter();

  useEffect(() => {
    const load = async () => {
      const geocode = (await locationAutocomplete({ input: locationString }).catch(() => ({ predictions: [] })))?.predictions?.[0] ?? {};
      const { value } = geocode;
      if (value) {
        const data = await getAddressDetailsFromPlaceId({ placeId: value }).catch(() => ({}));
        if (data?.result) {
          const { addressLine1, city, stateProvinceCode, countryCode, country, zipPostalCode, lat, lon, stateProvince } = data?.result;
          const fidelity = getFidelity(addressLine1, zipPostalCode, city, stateProvinceCode, countryCode);

          const urlLocation = {
            streetAddress: addressLine1,
            fidelity,
            lat,
            lon,
            locationString,
            stateProvince,
            stateProvinceCode,
            country,
            countryCode,
            city,
            zipPostalCode,
            locationOrigin: 'queryText',
          };
          setLocationFromQuery(urlLocation);
        }
      }
    };
    if (locationString) {
      load();
    }
  }, [locationString]);
  return null;
};
