import AAn from './AAn';
import { toSentenceCase } from './misc';

const parseTemplateString = (string, parameters) => {
  const [_, caseString, paramString, template] = string.match(/^([\^_]?)\[([A-Za-z&!]+):(.*)\]$/);
  const paramsFromString = paramString.split('&').map((txt) => ({ name: txt.replace('!', ''), negative: txt.includes('!') }));
  if (paramsFromString.some(({ name, negative }) => (!parameters[name] && !negative) || (parameters[name] && negative))) {
    return '';
  }
  let replacedString = template;
  paramsFromString.forEach(({ name }) => {
    replacedString = replacedString.replace(new RegExp(`\\[${name}\\]`, 'g'), parameters[name]);
  });
  replacedString = replaceIndefiniteArticle(replacedString);
  if (caseString === '^') replacedString = toSentenceCase(replacedString);
  else if (caseString === '_') replacedString = replacedString.toLowerCase();

  return replacedString;
};

const replaceIndefiniteArticle = (string) => {
  return string
    .replace(/\[a\/an\] [\s("'“‘-]?\w*\b/gim, (match) => {
      const input = match.replace('[a/an] ', '');
      const res = AAn.query(input.replace(/^[\s("'“‘-]+|\s+$/g, '')); // strip initial punctuation symbols
      return res + ' ' + input;
    })
    .replace('[a/an]', '');
};

/*
 * Simple Replace:
 * ${countryCode}
 *
 * Must Match:
 * [countryCode:in [countryCode]]
 * [countryCode&state:[countryCode] [state] land]
 * [state:[state]]
 *
 * Must Not Match:
 * [!state:Stuff when no state]
 *
 * Lowercase string
 * _[specialty:[specialty]]
 *
 * Sentence case string
 * ^[specialty:[specialty]]
 * ex.
 * ^[specialty:[specialty]] would become "Psychology" or if all upper case, such as "ENT" remain that way
 *
 * Add an indefinite article
 * [a/an]
 * ex.
 * [specialty:[a/an] [specialty]] would become "an ENT"
 *
 * Note: Spaces are meaningfull, they do not get trimmed.
 */
export const fillTemplate = (text, parameters) => {
  text = text?.replace(/&amp;/g, '&') ?? '';
  const regex = /[\^_]?\[[a-z!][A-Za-z0-9&!]+:[^[\]]*(?:[^[\]]*\[[^[\]]+?\][^[\]]*)*\]/g;
  const matches = text.match(regex) || [];
  matches.forEach((match) => {
    text = text.replace(match, parseTemplateString(match, parameters));
  });
  const simpleRegex = /\${([a-z][A-Za-z0-9]+)}/g;
  const simpleMatches = text.match(simpleRegex) || [];
  simpleMatches.forEach((match) => {
    const value = parameters[match.slice(2, -1)];
    text = text.replace(match, value ?? '');
  });
  return text;
};
